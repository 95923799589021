import React from "react";

import { Grid, Button } from "@mui/material";

const CategoriesPagination = ({
    categories,
    handleChangeCategory,
    selectedCategory,
}) => {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                }}
            >
                <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Grid
                        item
                        xs={4}
                        md={3}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <Button
                            color="warning"
                            variant={
                                selectedCategory === "all"
                                    ? "contained"
                                    : "text"
                            }
                            value="all"
                            onClick={handleChangeCategory}
                        >
                            All
                        </Button>
                    </Grid>
                    {categories.map((category) => (
                        <Grid
                            key={category.id}
                            item
                            xs={4}
                            md={3}
                            lg={2}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                color="warning"
                                variant={
                                    selectedCategory === category.name
                                        ? "contained"
                                        : "text"
                                }
                                value={category.name}
                                onClick={handleChangeCategory}
                            >
                                {category.name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CategoriesPagination;
