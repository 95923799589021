import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

// import axios from "axios";
import { useAuth } from "../../context/AuthContext";

import { Backdrop, CircularProgress, Grid, Stack } from "@mui/material";

// import SkeletonGenerator from "../../components/SkeletonGenerator/SkeletonGenerator";

// import Alerts from "./components/Alerts";

import { ApiService } from "../../services/ApiService";
import FilterBar from "../../components/filterBar/filterBar";
import SkeletonGenerator from "../../components/SkeletonGenerator/SkeletonGenerator";
import utils from "./utils";
import DialogComponent from "../../components/dialogComponent/dialogComponent";

// import "./productsList.scss";

const PostsList = () => {
    const { auth } = useAuth();

    const [pageSize, setPageSize] = useState(10);

    const [columns, setColumns] = useState([]);
    const [rowHeight, setRowHeight] = useState(100);
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);

    const [postTypeSelected, setPostTypeSelected] = useState(1);
    const [categorySelected, setCategorySelected] = useState("");

    const [openDialog, setOpenDialog] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [serverity, setSeverity] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [openLoader, setOpenLoader] = useState(false);

    const [selectedPostToDelete, setSelectedPostToDelete] = useState("");

    const handleClickOpen = (id) => {
        setOpenDialog(true);
        setSelectedPostToDelete(id);
    };

    const handleCloseNo = () => {
        setOpenDialog(false);
    };

    const showAlertFunction = (msg, alertSeverity) => {
        setAlertMessage(msg);
        setSeverity(alertSeverity);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
            setSeverity("");
        }, 2000);
    };

    const handleCloseYes = async () => {
        setOpenDialog(false);
        try {
            setOpenLoader(true);
            let res;
            if (postTypeSelected === 1) {
                res = await ApiService.deleteImagePost({
                    id: selectedPostToDelete,
                });
            } else {
                res = await ApiService.deleteVideoPost({
                    id: selectedPostToDelete,
                });
            }
            setOpenLoader(false);

            if (res.data.status === 200) {
                const postType = postTypeSelected === 1 ? "image" : "video";
                showAlertFunction(
                    `The ${postType} was successfully deleted!`,
                    "success"
                );
                fetchData(postTypeSelected);
            } else {
                showAlertFunction(
                    "An error occurred, please try again later!",
                    "error"
                );
            }
        } catch (error) {
            showAlertFunction(
                "An error occurred, please try again later!",
                "error"
            );
        }
    };

    const fetchData = async (postTypeId) => {
        if (postTypeId === 1) {
            setRowHeight(100);
            const res = await ApiService.getAllImagesPosts();
            setPosts(res.data);
            const imgCats = await ApiService.getImagesCategories();
            const allCategory = { id: 0, name: "All", posts: res.data };
            setCategories([allCategory, ...imgCats.data]);
            setCategorySelected(allCategory);
            const cols = utils.getImagesColumns(handleClickOpen);
            setColumns(cols);
        } else {
            setRowHeight(180);
            const res = await ApiService.getAllVideosPosts();
            setPosts(res.data);
            const videoCats = await ApiService.getVideosCategories();
            const allCategory = { id: 0, name: "All", posts: res.data };
            setCategories([allCategory, ...videoCats.data]);
            setCategorySelected(allCategory);
            const cols = utils.getVideoColumns(handleClickOpen);
            setColumns(cols);
        }
    };
    useEffect(() => {
        fetchData(1);
    }, []);

    const handleChangePostType = async (evt) => {
        const postTypeId = evt.target.value;
        setPostTypeSelected(postTypeId);
        fetchData(postTypeId);
        // if (evt.target.value === 1) {
        //     const imgCats = await ApiService.getImagesCategories();
        //     setCategories(imgCats.data);
        // } else {
        //     const videoCats = await ApiService.getVideosCategories();
        //     setCategories(videoCats.data);
        // }
    };

    const handleChangeCategory = async (evt) => {
        const category = evt.target.value;
        setCategorySelected(category);
        if (category.id !== 0) {
            const res = await ApiService.getAllPostsFromCategory(
                postTypeSelected,
                category.id
            );
            setPosts(res.data);
        } else {
            fetchData(postTypeSelected);
        }
    };

    const postTypes = [
        { id: 1, name: "Photo" },
        { id: 2, name: "Video" },
    ];

    return (
        <Grid
            container
            spacing={0}
            sx={{ display: "flex", flexDirection: "row" }}
        >
            <FilterBar
                postTypes={postTypes}
                postTypeSelected={postTypeSelected}
                handleChangePostType={handleChangePostType}
                categories={categories}
                categorySelected={categorySelected}
                handleChangeCategory={handleChangeCategory}
            ></FilterBar>

            <Grid item xs={12} margin="20px auto">
                <div
                    className="dataGrid"
                    style={{ marginBottom: "20px", minHeight: 480 }}
                >
                    {showSkeleton === false ? (
                        <DataGrid
                            sortingOrder={["desc", "asc"]}
                            rowHeight={rowHeight}
                            autoHeight
                            rows={posts}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No results
                                    </Stack>
                                ),
                            }}
                        />
                    ) : (
                        <SkeletonGenerator
                            quantity={10}
                            height={60}
                        ></SkeletonGenerator>
                    )}
                </div>

                {openDialog && (
                    <DialogComponent
                        open={openDialog}
                        handleCloseNo={handleCloseNo}
                        handleCloseYes={handleCloseYes}
                        title={"Delete Post"}
                        text={`Are you sure you want to delete this ${
                            postTypeSelected === 1 ? "Image" : "Video"
                        }?`}
                    />
                )}

                {openLoader && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={openLoader}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Grid>
        </Grid>
    );
};

export default PostsList;
