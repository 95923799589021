import React from "react";

import HomeNavBar from "../../components/homeNavBar/homeNavBar";
import Header from "../../components/header/header";
import About from "../../components/about/about";
import PortfolioArea from "../../components/portfolioArea/portfolioArea";
import Contact from "../../components/contact/contact";
import Footer from "../../components/footer/footer";

import "./home.css";

export default function Home() {
    return (
        <main className="flex min-h-screen flex-col items-center justify-between p-24">
            {/* <!-- Navigation--> */}
            <HomeNavBar></HomeNavBar>
            {/* <!-- Masthead--> */}
            <Header></Header>

            {/* <!-- About--> */}
            <About></About>

            {/* <!-- Portfolio Grid--> */}
            <PortfolioArea></PortfolioArea>

            {/* <!-- Contact--> */}
            <Contact></Contact>

            {/* <!-- Footer--> */}
            <Footer></Footer>
        </main>
    );
}
