import React, { useState } from "react";
import {
    FormControl,
    TextField,
    Grid,
    Backdrop,
    CircularProgress,
    Alert,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { ApiService } from "../../services/ApiService";

const Login = () => {
    const { setAuth, setUserName } = useAuth();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);

    const [openLoader, setOpenLoader] = React.useState(false);
    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();

    const checkFormData = (evt) => {
        evt.preventDefault();
        setShowError(false);
        if (!email.trim()) {
            setEmailError(true);
            return;
        } else {
            setEmailError(false);
        }
        if (!password.trim()) {
            setPasswordError(true);
            return;
        } else {
            setPasswordError(false);
        }
        setOpenLoader(true);

        loginUser();
    };

    const loginUser = async () => {
        try {
            const user = {
                email: email,
                password: password,
            };

            const res = await ApiService.login(user);

            if (res.data.auth === false) {
                setShowError(true);
            } else {
                localStorage.setItem("jwt-photography-token", res.data.token);
                localStorage.setItem("userName", res.data.userName);
                setAuth(res.data.token);
                setUserName(res.data.userName);
                navigate("/admin");
            }
        } catch (error) {
            setShowError(true);
            console.log(error);
        }
        setOpenLoader(false);
    };

    return (
        <Grid
            container
            sx={{
                height: "100vh",
                display: "flex",
            }}
        >
            <Grid item md={6} xs={12}>
                <img
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "cover" }}
                    src="/img/about/profile.jpeg"
                    alt="..."
                />
            </Grid>
            <Grid item md={6} xs={10} sx={{ margin: "auto" }}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    <img
                        width={100}
                        height={100}
                        style={{ objectFit: "contain" }}
                        src="/img/logo.png"
                        alt="..."
                    />
                    <h4 style={{ textAlign: "center", fontWeight: 600 }}>
                        Sign into your account
                    </h4>
                </Grid>

                <form onSubmit={checkFormData}>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <FormControl sx={{ width: "55ch" }}>
                                <TextField
                                    error={emailError}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    id="email"
                                    margin="normal"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setEmailError(false);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <FormControl sx={{ width: "55ch" }}>
                                <TextField
                                    error={passwordError}
                                    id="outlined-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    margin="normal"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setPasswordError(false);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {showError === true ? (
                            <Grid
                                item
                                xs={12}
                                md={7}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Alert severity="error">
                                    El email o la contraseñas son incorrectos,
                                    intente nuevamente!
                                </Alert>
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            xs={7}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2%",
                                marginBottom: "4%",
                            }}
                        >
                            <button
                                className="btn btn-bordo"
                                type="submit"
                                variant="contained"
                                style={{ width: "100%", fontWeight: 700 }}
                            >
                                SIGN IN
                            </button>
                        </Grid>
                    </Grid>
                </form>

                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        </Grid>
    );
};

export default Login;
