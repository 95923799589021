import React from "react";

const About = () => {
    return (
        <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h2
                        className="section-heading text-uppercase"
                        style={{ marginBottom: "5rem" }}
                    >
                        About me
                    </h2>
                    {/* <h3 className="section-subheading text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                    </h3> */}
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="team-member">
                            <img
                                width={100}
                                height={100}
                                className="mx-auto rounded-circle"
                                style={{ objectFit: "cover" }}
                                src="/img/about/profile.jpeg"
                                alt="..."
                            />
                            <h4>Clara Asprella</h4>
                            <p className="text-muted">Film Student</p>
                            <a
                                className="btn btn-dark btn-social mx-2"
                                aria-label="Clara Asprella Instagram Profile"
                                href="https://www.instagram.com/asprellafilm/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a
                                className="btn btn-dark btn-social mx-2"
                                aria-label="Clara Asprella YouTube Profile"
                                href="https://www.youtube.com/@asprellafilm"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fab fa-youtube"></i>
                            </a>
                            <a
                                className="btn btn-dark btn-social mx-2"
                                aria-label="Clara Asprella Indeed Profile"
                                href="https://indeed.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    style={{
                                        width: "95%",
                                        height: "35px",
                                        borderRadius: "50%",
                                        marginRight: "1px",
                                    }}
                                    alt="Indeed Icon"
                                    src="/indeed.png"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        My name is Clara Asprella, I am from La Plata, Argentina
                        and I will be receiving a bachelor's degree in Film from
                        Campbellsville University in May 2026. I came to The US
                        in 2022 to pursue my dream of becoming a filmmaker while
                        also playing college tennis. My journey into this world
                        began with a deep-seated fascination for capturing
                        moments through the lens of a camera. At Campbellsville,
                        I started working as a sports videographer and
                        photographer and having the opportunity to document
                        students-athletes' life. This has helped me develop new
                        skills with cameras and the editing process. My goal is
                        to tell stories that evoke emotion and use imagery to
                        tell narratives that resonate with audiences. I am
                        filled with determination to continue my journey as a
                        filmmaker, eager to share my life perspective and
                        storytelling with the world. Every project would aim to
                        inspire and captivate, leaving an impact on those who
                        like my work.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
