import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Layout from "./Layout";

import MasonryImageList from "../../components/imageList/imageList";

import { ApiService } from "../../services/ApiService";
import Grid from "@mui/material/Grid";
import CustomPagination from "../../components/customPagination/customPagination";
import PortfolioModal from "../../components/portfolioModal/porftolioModal";
import Skeletons from "../../components/skeletons/skeletons";
import CategoriesPagination from "../../components/categoriesPagination/categoriesPagination";

const Photography = () => {
    const [images, setImages] = useState([]); // Estado para almacenar las imágenes cargadas
    const [totalPages, setTotalPages] = useState("");
    const [showSkeletons, setShowSkeletons] = useState(false);
    const [showNoImages, setShowNoImages] = useState(false);
    const pageSize = 15; // Cantidad de imágenes por página

    const navigate = useNavigate();

    // const location = useLocation();
    const [params, setParams] = useSearchParams();
    const urlPage = params.get("page");
    const [page, setPage] = useState(urlPage || 1); // Página actual de imágenes

    const [selectedImage, setSelectedImage] = useState("");
    const [open, setOpen] = useState(false);

    const urlCategory = params.get("category");
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(urlCategory);

    const handleChangePage = async (
        event,
        pageNumber,
        category = urlCategory
    ) => {
        navigate(
            `/portfolio/photography?page=${pageNumber}&category=${category}`
        );

        setShowSkeletons(true);
        await fetchData(pageNumber, category);
        setShowSkeletons(false);
    };

    const fetchData = async (pageNumber, category) => {
        try {
            setPage(pageNumber);
            const res = await ApiService.getPortfolioImages(
                pageNumber,
                pageSize,
                category
            );
            setImages(res.data);
            if (res.data.length === 0) {
                setShowNoImages(true);
            } else {
                setShowNoImages(false);
            }

            const resTotalPages = await ApiService.getImagesTotalPages(
                pageNumber,
                pageSize,
                category
            );

            setTotalPages(resTotalPages.data.totalPages);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (urlCategory === null) {
            setSelectedCategory("all");
        } else {
            setSelectedCategory(urlCategory);
        }
        // console.log("cambio url");
        // fetchData(urlPage, urlCategory);
        if (urlPage !== null) {
            fetchData(parseInt(urlPage), urlCategory);
            // handleChangePage(null, parseInt(page));
        } else {
            fetchData(1, urlCategory);
            // handleChangePage(null, parseInt(1));
        }

        // const getTotalPages = async () => {
        //     const resTotalPages = await ApiService.getImagesTotalPages(
        //         page,
        //         pageSize,
        //         selectedCategory
        //     );

        //     setTotalPages(resTotalPages.data.totalPages);
        // };

        // getTotalPages();
    }, [urlPage, urlCategory]);

    useEffect(() => {
        const getCategories = async () => {
            const res = await ApiService.getImagesCategories();
            setCategories(res.data);
        };

        getCategories();
    }, []);

    const handleSelectImage = (image) => {
        setSelectedImage(image.path);
        setOpen(true);
    };

    const handleChangeCategory = (evt) => {
        setPage(1);
        setSelectedCategory(evt.target.value);
        handleChangePage(null, 1, evt.target.value);
    };

    return (
        <Layout>
            <CategoriesPagination
                categories={categories}
                selectedCategory={selectedCategory}
                handleChangeCategory={handleChangeCategory}
            />
            {showNoImages && (
                <Grid container>
                    <Grid item xs={12} sx={{ marginTop: "5%" }}>
                        <h1
                            style={{
                                color: "white",
                                textTransform: "uppercase",
                                textAlign: "center",
                            }}
                        >
                            No images to show for this category
                        </h1>
                    </Grid>
                </Grid>
            )}
            {showSkeletons && <Skeletons height={500}></Skeletons>}

            {images.length > 0 && (
                <Grid
                    container
                    padding={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Grid item xs={12}>
                        <MasonryImageList
                            images={images}
                            handleSelectImage={handleSelectImage}
                        ></MasonryImageList>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <CustomPagination
                            totalPages={totalPages}
                            actualPage={page}
                            handleChange={handleChangePage}
                        ></CustomPagination>
                    </Grid>
                    {open && (
                        <PortfolioModal
                            open={open}
                            setOpen={setOpen}
                            image={selectedImage}
                        ></PortfolioModal>
                    )}
                </Grid>
            )}
        </Layout>
    );
};

export default Photography;
