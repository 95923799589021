import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export default function PortfolioNavBar() {
    const { auth } = useAuth();

    const menuItems = [
        {
            id: 1,
            title: "Photography",
            url: "/portfolio/photography",
        },
        {
            id: 2,
            title: "Videos",
            url: "/portfolio/videos",
        },
    ];

    if (auth !== false) {
        menuItems.push({ id: 3, title: "Admin", url: "/admin" });
    }

    const location = useLocation();
    const pathSegments = location.pathname.split("/"); // Divide la URL en partes separadas por "/"
    const category = pathSegments[2]; // Obtiene la tercera parte después de dividir la URL

    return (
        <nav
            className="navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink"
            id="mainNav"
        >
            <div className="container">
                <Link className="navbar-brand" to="/">
                    {/* <a className="navbar-brand" href="#page-top"> */}
                    <img
                        // width={140}
                        // height={24}
                        src="/img/logo.png"
                        alt="..."
                    />
                    {/* </a> */}
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        {menuItems.map((menuItem) => (
                            <li className="nav-item" key={menuItem.id}>
                                <Link
                                    className={`nav-link ${
                                        category ===
                                        menuItem.title.toLowerCase()
                                            ? "active"
                                            : ""
                                    }`}
                                    to={menuItem.url}
                                >
                                    {menuItem.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
