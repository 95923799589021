import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const getImagesColumns = (handleClickOpen) => {
    return [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <img
                        alt="small"
                        src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${params.row.path}`}
                        style={{ width: "150px", padding: "10px" }}
                    />
                );
            },
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) => params.row.category.name,
        },

        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        title="Delete"
                        sx={{
                            "&:hover": {
                                background: "none",
                            },
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleClickOpen(params.id);
                        }}
                    >
                        <DeleteIcon
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#d3302fc2",
                                },
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete
                        </DeleteIcon>
                    </IconButton>
                );
            },
        },
    ];
};
const getVideoColumns = (handleClickOpen) => {
    return [
        {
            field: "video",
            headerName: "Video",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <iframe
                        width="250"
                        height="150"
                        src={params.row.url}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                    ></iframe>
                );
            },
        },

        {
            field: "category",
            headerName: "Category",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) => params.row.category.name,
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        title="Delete"
                        sx={{
                            "&:hover": {
                                background: "none",
                            },
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleClickOpen(params.id);
                        }}
                    >
                        <DeleteIcon
                            sx={{
                                cursor: "pointer",
                                "&:hover": {
                                    color: "#d3302fc2",
                                },
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete
                        </DeleteIcon>
                    </IconButton>
                );
            },
        },
    ];
};

const utils = {
    getImagesColumns,
    getVideoColumns,
};

export default utils;
