import React from "react";
import PortfolioNavBar from "../../components/portfolioNavBar/portfolioNavBar";

const Layout = ({ children }) => {
    return (
        <div style={{ backgroundColor: "#000000e0", minHeight: "100vh" }}>
            <PortfolioNavBar></PortfolioNavBar>
            <div style={{ display: "block", height: "80px" }}></div>
            {children}
        </div>
    );
};

export default Layout;
