import React, { useContext, useState, useEffect } from "react";
import { verifyToken } from "../utils/verifyToken";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [auth, setAuth] = useState("");
    const [userName, setUserName] = useState(
        localStorage.getItem("userName") || ""
    );
    useEffect(() => {
        async function checkToken() {
            const isAvailable = await verifyToken();
            if (isAvailable) {
                setAuth(localStorage.getItem("jwt-photography-token"));
            } else {
                setAuth(false);
            }
        }

        checkToken();
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, userName, setUserName }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
