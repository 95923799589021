import React, { useState } from "react";
import { ApiService } from "../../services/ApiService";
import { Alert, Backdrop, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export default function Contact() {
    const [data, setData] = useState({
        name: "",
        email: "",
        phone: "",
        text: "",
    });

    const [showLoader, setShowLoader] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const setAlertConfig = (msg, svrt) => {
        setShowAlert(true);
        setAlertMessage(msg);
        setSeverity(svrt);
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
            setSeverity("");
        }, 2000);
    };

    const checkFormData = async (evt) => {
        evt.preventDefault();
        if (!data.name.trim()) {
            setAlertConfig("Please complete all fields!", "warning");
            return;
        }
        if (!data.email.trim()) {
            setAlertConfig("Please complete all fields!", "warning");
            return;
        }
        if (!data.phone.trim()) {
            setAlertConfig("Please complete all fields!", "warning");
            return;
        }
        if (!data.text.trim()) {
            setAlertConfig("Please complete all fields!", "warning");
            return;
        }

        saveData();
    };

    const saveData = async () => {
        setShowLoader(true);
        const res = await ApiService.sendEmail(data);
        setShowLoader(false);
        if (res.status === 200) {
            setData({ name: "", email: "", phone: "", text: "" });
            setAlertConfig(
                "The message has been sent successfully!",
                "success"
            );
        } else {
            setAlertConfig(
                "An error occurred, please try again later!",
                "error"
            );
        }
    };

    return (
        <section className="page-section" id="contact">
            <div className="container">
                <div className="text-center">
                    <h2
                        className="section-heading text-uppercase"
                        style={{ marginBottom: "4rem" }}
                    >
                        Contact
                    </h2>
                </div>
                <form
                    onSubmit={checkFormData}
                    id="contactForm"
                    data-sb-form-api-token="API_TOKEN"
                >
                    <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* <!-- Name input--> */}
                                <input
                                    className="form-control"
                                    id="name"
                                    required
                                    type="text"
                                    placeholder="Name *"
                                    data-sb-validations="required"
                                    value={data.name}
                                    onChange={(evt) =>
                                        setData({
                                            ...data,
                                            name: evt.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                {/* <!-- Email address input--> */}
                                <input
                                    className="form-control"
                                    id="email"
                                    required
                                    type="email"
                                    placeholder="Email *"
                                    data-sb-validations="required,email"
                                    value={data.email}
                                    onChange={(evt) =>
                                        setData({
                                            ...data,
                                            email: evt.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group mb-md-0">
                                {/* <!-- Phone number input--> */}
                                <input
                                    className="form-control"
                                    id="phone"
                                    required
                                    type="tel"
                                    placeholder="Phone number *"
                                    data-sb-validations="required"
                                    value={data.phone}
                                    onChange={(evt) =>
                                        setData({
                                            ...data,
                                            phone: evt.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-group-textarea mb-md-0">
                                {/* <!-- Message input--> */}
                                <textarea
                                    className="form-control"
                                    id="message"
                                    required
                                    placeholder="Message *"
                                    data-sb-validations="required"
                                    value={data.text}
                                    onChange={(evt) =>
                                        setData({
                                            ...data,
                                            text: evt.target.value,
                                        })
                                    }
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        <button
                            className="btn btn-bordo btn-xl text-uppercase"
                            id="submitButton"
                            type="submit"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
            {showAlert && (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity={severity}
                    variant="filled"
                    sx={{
                        zIndex: 999,
                        position: "fixed",
                        top: "50%",
                        left: "45%",
                    }}
                >
                    {alertMessage}
                </Alert>
            )}
            {showLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={showLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </section>
    );
}
