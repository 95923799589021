import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import ProtectedRoute from "./pages/Layout/Layout";
import { useAuth } from "./context/AuthContext";
import Admin from "./pages/Admin/Admin";
import Login from "./pages/LogIn/Login";
import Photography from "./pages/Portfolio/Photography";
import Videos from "./pages/Portfolio/Videos";

function App() {
    const { auth } = useAuth();

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route path="/login" element={<Login></Login>}></Route>
                <Route
                    path="/portfolio/photography"
                    element={<Photography></Photography>}
                ></Route>
                <Route
                    path="/portfolio/videos"
                    element={<Videos></Videos>}
                ></Route>

                <Route element={<ProtectedRoute isAllowed={auth !== false} />}>
                    <Route path="/admin" element={<Admin></Admin>}></Route>
                </Route>

                <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
            </Routes>
        </Router>
    );
}

export default App;
