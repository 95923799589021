import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
    useTheme,
    useMediaQuery,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
    AppBar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListIcon from "@mui/icons-material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PostForm from "../PostForm/PostForm";
import PostsList from "../PostsList/PostsList";
import DialogComponent from "../../components/dialogComponent/dialogComponent";
import CategoriesForm from "../CategoriesForm/CategoriesForm";

const drawerWidth = 260;

function Admin(props) {
    const { window } = props;
    const [searchParams] = useSearchParams();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const [selectedSection, setSelectedSection] = useState(
        JSON.parse(localStorage.getItem("sectionSelected")) || 1
    );
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [resetUrl, setResetUrl] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const menuItems = [
        {
            id: 1,
            title: "List",
            icon: <ListIcon></ListIcon>,
            component: <PostsList></PostsList>,
        },
        {
            id: 2,
            title: "New Post",
            icon: <AddBoxIcon></AddBoxIcon>,
            component: <PostForm></PostForm>,
        },
        {
            id: 3,
            title: "Categories",
            icon: <AddBoxIcon></AddBoxIcon>,
            component: <CategoriesForm></CategoriesForm>,
        },
        {
            id: 4,
            title: "Sign out",
            icon: <LogoutIcon></LogoutIcon>,
            component: null,
        },
    ];

    useEffect(() => {
        setSelectedComponent(
            menuItems.find((item) => item.id === selectedSection).component
        );
        let id = searchParams.get("edit");
        if (id !== null) {
            // setEditModeState(true);
            setResetUrl(true);
            setSelectedSection(2);
        }
    }, []);

    const handleClickMenuItem = (item) => {
        if (item.id !== 4) {
            setSelectedSection(item.id);
            setSelectedComponent(item.component);
            localStorage.setItem("sectionSelected", item.id);
            if (resetUrl) {
                navigate("/admin");
                setResetUrl(false);
            }
        } else {
            handleClickOpen();
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseNo = () => {
        setOpen(false);
    };
    const handleCloseYes = () => {
        setOpen(false);
        // setAuth(false);
        // setUserName("");
        localStorage.clear();
        navigate("/");
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {menuItems.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                handleClickMenuItem(item);
                                handleDrawerToggle();
                            }}
                            selected={item.id === selectedSection}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {/* <Divider /> */}
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "#212529",
                }}
            >
                <Toolbar
                    sx={{
                        display: "flex",
                        justifyContent: { xs: "space-between", sm: "flex-end" },
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        sx={{ marginRight: "1%" }}
                        onClick={() => navigate("/")}
                    >
                        <HomeIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                {selectedComponent}
            </Box>

            {open && (
                <DialogComponent
                    open={open}
                    handleCloseNo={handleCloseNo}
                    handleCloseYes={handleCloseYes}
                    title={"Sign Out"}
                    text={"Are you sure you want to log out?"}
                />
            )}
        </Box>
    );
}

export default Admin;
