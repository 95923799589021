import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Skeleton from "@mui/material/Skeleton";

import "./styles.css";

export default function MasonryImageList({ images, handleSelectImage }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [colsNumber, setColsNumber] = useState(3);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // El segundo argumento debe ser un array vacío para que el efecto solo se ejecute una vez

    useEffect(() => {
        if (screenWidth <= 430) {
            setColsNumber(1);
            return;
        }
        if (screenWidth <= 768) {
            setColsNumber(2);
            return;
        }

        setColsNumber(3);
    }, [screenWidth]); // Este efecto se ejecutará cada vez que cambie el tamaño de la pantalla

    return (
        <Box
            sx={{
                overflowY: "hidden",
                overflowX: "hidden",
                padding: "5px",
            }}
        >
            <ImageList variant="masonry" cols={colsNumber} gap={7}>
                {images.map((image) => (
                    <ImageListItem
                        key={image.id}
                        className="image-container"
                        onClick={() => handleSelectImage(image)}
                    >
                        <img
                            srcSet={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${image.path}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${image.path}?w=248&fit=crop&auto=format`}
                            alt={image.description}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}
