import React, { useState } from "react";
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

import { ApiService } from "../../services/ApiService";
import { DataGrid } from "@mui/x-data-grid";
import SkeletonGenerator from "../../components/SkeletonGenerator/SkeletonGenerator";
import DialogComponent from "../../components/dialogComponent/dialogComponent";

const CategoriesForm = () => {
    const [selectedPostType, setSelectedPostType] = useState("");
    const [name, setName] = useState("");

    const [categories, setCategories] = useState([]);

    const [showAddInput, setShowAddInput] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);

    const [selectedCategoryToDelete, setSelectedCategoryToDelete] =
        useState("");
    const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState("");
    const [editMode, setEditMode] = useState(false);

    const [pageSize, setPageSize] = useState(10);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const resetForm = () => {
        setSelectedPostType("");
        // setSelectedCategory("");
        // setCategories([]);
        // setVideoUrl("");
        // setImagePreviews("");
    };

    const fetchData = async (postType) => {
        if (postType === 1) {
            const imgCategs = await ApiService.getImagesCategories();
            setCategories(imgCategs.data);
        } else {
            const imgCategs = await ApiService.getVideosCategories();
            setCategories(imgCategs.data);
        }
    };

    const handleChangePostType = async (evt) => {
        const typeId = evt.target.value;
        setName("");
        setSelectedPostType(typeId);
        fetchData(typeId);
        // if (typeId === 1) {
        //     const imgCategs = await ApiService.getImagesCategories();
        //     setCategories(imgCategs.data);
        // } else {
        //     const imgCategs = await ApiService.getVideosCategories();
        //     setCategories(imgCategs.data);
        // }
    };

    const postTypes = [
        { id: 1, name: "Photo" },
        { id: 2, name: "Video" },
    ];

    const handleClickOpen = (id) => {
        setSelectedCategoryToDelete(id);
        setOpenDialog(true);
    };

    const handleClickEdit = (subcategory) => {
        setEditMode(true);
        setShowAddInput(true);
        setSelectedCategoryToEdit(subcategory);
        setName(subcategory.name);
    };

    const handleCloseNo = () => {
        setOpenDialog(false);
    };

    const showAlertFunction = (msg, alertSeverity) => {
        setAlertMessage(msg);
        setSeverity(alertSeverity);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertMessage("");
            setSeverity("");
        }, 2000);
    };

    const handleCloseYes = async () => {
        try {
            const res = await ApiService.deleteCategory({
                postTypeId: selectedPostType,
                categoryId: selectedCategoryToDelete,
            });
            if (res.data.status === 200) {
                fetchData(selectedPostType);
                showAlertFunction(
                    "The category has been successfully deleted!",
                    "success"
                );
            } else {
                showAlertFunction(
                    "An error occurred, please try again later!",
                    "error"
                );
            }
        } catch (error) {
            showAlertFunction(
                "An error occurred, please try again later!",
                "error"
            );
        }
        setOpenDialog(false);
    };

    const saveData = async (evt) => {
        try {
            evt.preventDefault();
            setShowLoader(true);

            let data;
            if (editMode) {
                data = {
                    categoryToEdit: selectedCategoryToEdit,
                    name: name,
                };
            } else {
                data = {
                    postType: selectedPostType,
                    name: name,
                };
            }

            let res;
            if (editMode === false) {
                res = await ApiService.newCategory(data);
            } else {
                res = await ApiService.editCategory(data);
            }
            setShowLoader(false);

            if (res.status === 200) {
                fetchData(selectedPostType);
                setShowAlert(true);
                const action = editMode === false ? "created" : "updated";
                showAlertFunction(
                    `The category was ${action} successfully!`,
                    "success"
                );
            } else {
                showAlertFunction(
                    "An error occurred, please try again later!",
                    "error"
                );
            }
        } catch (error) {
            showAlertFunction(
                "An error occurred, please try again later!",
                "error"
            );
        }
    };
    const columns = [
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            disableColumnMenu: true,
            sortable: true,
            valueGetter: (params) => params.row.name,
        },

        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        <IconButton
                            title="Delete"
                            sx={{
                                "&:hover": {
                                    background: "none",
                                },
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                handleClickEdit(params.row);
                            }}
                        >
                            <EditIcon
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#d3302fc2",
                                    },
                                }}
                                variant="contained"
                                color="error"
                            >
                                Edit
                            </EditIcon>
                        </IconButton>
                        <IconButton
                            title="Delete"
                            sx={{
                                "&:hover": {
                                    background: "none",
                                },
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                handleClickOpen(params.id);
                            }}
                        >
                            <DeleteIcon
                                sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: "#d3302fc2",
                                    },
                                }}
                                variant="contained"
                                color="error"
                            >
                                Delete
                            </DeleteIcon>
                        </IconButton>
                    </div>
                );
            },
        },
    ];
    return (
        <Grid
            container
            spacing={0}
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            <Grid item xs={12} md={6}>
                <Box className="title">
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                        }}
                    >
                        Categories
                    </Typography>
                </Box>

                <FormControl fullWidth>
                    <TextField
                        id="postType"
                        name="postType"
                        select
                        label="Post Type"
                        value={selectedPostType}
                        onChange={handleChangePostType}
                        margin="normal"
                        // disabled={showLoader}
                    >
                        {postTypes.map((postType) => (
                            <MenuItem key={postType.id} value={postType.id}>
                                {postType.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                {!showAddInput && selectedPostType !== "" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowAddInput(true)}
                        >
                            New
                        </Button>
                    </div>
                )}

                {showAddInput && (
                    <form onSubmit={saveData}>
                        <FormControl fullWidth>
                            <TextField
                                // error={nameError}
                                value={name}
                                fullWidth
                                label="Category name"
                                name="name"
                                id="name"
                                margin="normal"
                                onChange={(e) => {
                                    setName(e.target.value);
                                    // setNameError(false);
                                }}
                                disabled={showLoader}
                            />
                        </FormControl>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "10px",
                            }}
                        >
                            <Button
                                // sx={{ marginTop: "25px" }}
                                // onClick={saveData}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                type="submit"
                            >
                                Save
                            </Button>

                            <Button
                                sx={{ marginLeft: "10px" }}
                                onClick={() => {
                                    setEditMode(false);
                                    setShowAddInput(false);
                                    setSelectedCategoryToEdit("");
                                    setName("");
                                }}
                                variant="contained"
                                className="pink-btn"
                                disabled={showLoader}
                                color="error"
                            >
                                Cancel
                            </Button>
                        </Box>
                    </form>
                )}
            </Grid>
            {selectedPostType && (
                <Grid item xs={12} md={10} xl={7}>
                    <div
                        style={{
                            minHeight: 480,
                            height: "100%",
                            width: "100%",
                            marginTop: "25px",
                        }}
                    >
                        <DataGrid
                            sortingOrder={["desc", "asc"]}
                            autoHeight
                            rows={categories}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: pageSize },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            onPageSizeChange={(newPageSize) =>
                                setPageSize(newPageSize)
                            }
                            disableSelectionOnClick
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack
                                        height="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        No results
                                    </Stack>
                                ),
                            }}
                        />
                    </div>
                </Grid>
            )}

            {openDialog && (
                <DialogComponent
                    open={openDialog}
                    handleCloseNo={handleCloseNo}
                    handleCloseYes={handleCloseYes}
                    title={"Delete Category"}
                    text={
                        "Are you sure you want to delete this Category? All posts related to this category will be deleted"
                    }
                />
            )}

            {showAlert && (
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity={severity}
                    sx={{ position: "fixed", top: "50%" }}
                >
                    {alertMessage}
                </Alert>
            )}

            {showLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={showLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Grid>
    );
};

export default CategoriesForm;
