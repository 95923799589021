import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const CustomPagination = ({ totalPages, actualPage, handleChange }) => {
    return (
        <Stack spacing={2}>
            <Pagination
                sx={{ backgroundColor: "white", borderRadius: "10px" }}
                count={parseInt(totalPages) || 0}
                page={parseInt(actualPage) || 1}
                onChange={handleChange}
            />
        </Stack>
    );
};

export default CustomPagination;
