import React from "react";

import { FormControl, Grid, TextField, MenuItem } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
// import ClearIcon from "@mui/icons-material/Clear";

const FilterBar = ({
    postTypes,
    postTypeSelected,
    handleChangePostType,
    categories,
    categorySelected,
    handleChangeCategory,
    posts,
}) => {
    return (
        <>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        id="postType"
                        name="postType"
                        select
                        label="Post type"
                        value={postTypeSelected}
                        onChange={handleChangePostType}
                        margin="normal"
                        nooptionstext={"No post types to show"}
                    >
                        {postTypes.length === 0 ? (
                            <MenuItem disabled>No post types to show</MenuItem>
                        ) : (
                            postTypes.map((postType) => (
                                <MenuItem key={postType.id} value={postType.id}>
                                    {postType.name}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth sx={{ padding: "5px" }}>
                    <TextField
                        id="category"
                        name="category"
                        select
                        label="Category"
                        value={categorySelected}
                        onChange={handleChangeCategory}
                        // helperText="Por favor seleccione una categoría"
                        margin="normal"
                        // disabled={showLoader}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
        </>
    );
};

export default FilterBar;
