import React, { useState } from "react";
import { Button, FormControl, Grid, MenuItem, TextField } from "@mui/material";

// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { DataGrid, esES } from "@mui/x-data-grid";

const FormInputs = ({
    checkFormData,
    showLoader,
    selectedPostType,
    handleChangePostType,
    categories,
    selectedCategory,
    handleChangeCategory,
    videoUrl,
    handleChangeVideoUrl,
    imagePreviews,
    setImagePreviews,
}) => {
    // const [category, setCategory] = useState("");

    // Form errors
    const [categoryError, setCategoryError] = useState(false);

    const handleImageChange = (event) => {
        const files = event.target.files;
        const previews = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            reader.onload = (e) => {
                previews.push(e.target.result);
                if (previews.length === files.length) {
                    setImagePreviews(previews);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const postTypes = [
        { id: 1, name: "Photo" },
        { id: 2, name: "Video" },
    ];

    return (
        <form onSubmit={checkFormData} encType="multipart/form-data">
            <FormControl fullWidth>
                <TextField
                    error={categoryError}
                    id="postType"
                    name="postType"
                    select
                    label="Post Type"
                    value={selectedPostType}
                    onChange={handleChangePostType}
                    margin="normal"
                    disabled={showLoader}
                >
                    {postTypes.map((postType) => (
                        <MenuItem key={postType.id} value={postType.id}>
                            {postType.name}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    error={categoryError}
                    id="category"
                    name="category"
                    select
                    label="Category"
                    value={selectedCategory}
                    onChange={handleChangeCategory}
                    margin="normal"
                    disabled={categories.length === 0}
                >
                    {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>

            {selectedPostType === 1 && (
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <div>
                        <Button
                            variant="contained"
                            component="label"
                            className="pink-btn"
                            disabled={selectedCategory === ""}
                        >
                            Upload photos
                            <input
                                type="file"
                                name="images"
                                id="images"
                                multiple
                                hidden
                                onChange={handleImageChange}
                            />
                        </Button>
                        <small style={{ marginLeft: "10px" }}>
                            {imagePreviews.length} selected photos
                        </small>
                    </div>
                    {imagePreviews.length > 0 ? (
                        <Grid container spacing={1} mt={2}>
                            <Grid item xs={12}>
                                <h4>IMAGES:</h4>
                            </Grid>
                            {imagePreviews.map((image, index) => (
                                <Grid item xs={12} md={3} mt={2} key={index}>
                                    <img
                                        src={image}
                                        alt="Vista previa de la imagen"
                                        style={{
                                            width: "100%",
                                            height: "150px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        ""
                    )}
                </div>
            )}
            {selectedPostType === 2 && (
                <FormControl fullWidth>
                    <TextField
                        error={categoryError}
                        type="text"
                        id="video"
                        name="video"
                        label="Video URL"
                        value={videoUrl}
                        onChange={handleChangeVideoUrl}
                        margin="normal"
                        disabled={selectedCategory === ""}
                    ></TextField>
                </FormControl>
            )}
            <div
                style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                }}
            >
                {selectedPostType === 1 ? (
                    <Button
                        // onClick={checkFormData}
                        variant="contained"
                        className="pink-btn"
                        disabled={imagePreviews.length === 0}
                        type="submit"
                    >
                        Save
                    </Button>
                ) : (
                    <Button
                        // onClick={checkFormData}
                        variant="contained"
                        className="pink-btn"
                        disabled={videoUrl === ""}
                        type="submit"
                    >
                        Save
                    </Button>
                )}
            </div>
        </form>
    );
};

export default FormInputs;
