import axios from "axios";

const headersJson = {
    headers: {
        "Content-Type": "application/json",
    },
};

const headersJsonAuth = () => {
    const token = localStorage.getItem("jwt-photography-token");
    return {
        headers: {
            "Content-Type": "application/json",
            Authorization: token,
        },
    };
};

const headersMultipart = () => {
    const token = localStorage.getItem("jwt-photography-token");
    return {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
        },
    };
};

const api = process.env.REACT_APP_BACKEND_URL;

export const ApiService = {
    login: async (data) => {
        const res = await axios.post(`${api}/users/login`, data);
        return res;
    },
    getAllImagesPosts: async () => {
        const res = await axios.get(`${api}/posts/allImages`);
        return res;
    },
    getAllVideosPosts: async () => {
        const res = await axios.get(`${api}/posts/allVideos`);
        return res;
    },
    getAllPostsFromCategory: async (postType, categoryId) => {
        const res = await axios.get(
            `${api}/posts/getAllPostsFromCategory/${postType}/${categoryId}`
        );
        return res;
    },
    getPortfolioImages: async (page, pageSize, category) => {
        const res = await axios.get(
            `${api}/posts/getImages?page=${page}&pageSize=${pageSize}&category=${category}`
        );
        return res;
    },
    getPortfolioVideos: async (page, pageSize, category) => {
        const res = await axios.get(
            `${api}/posts/getVideos?page=${page}&pageSize=${pageSize}&category=${category}`
        );
        return res;
    },
    getImagesTotalPages: async (page, pageSize, category) => {
        const res = await axios.get(
            `${api}/posts/calculateImagesTotalPages?page=${page}&pageSize=${pageSize}&category=${category}`
        );
        return res;
    },
    getVideosTotalPages: async (page, pageSize, category) => {
        const res = await axios.get(
            `${api}/posts/calculateVideosTotalPages?page=${page}&pageSize=${pageSize}&category=${category}`
        );
        return res;
    },

    getImagesCategories: async () => {
        const res = await axios.get(`${api}/categories/getImagesCategories`);
        return res;
    },
    getVideosCategories: async () => {
        const res = await axios.get(`${api}/categories/getVideosCategories`);
        return res;
    },

    uploadImagePost: async (data) => {
        const res = await axios.post(
            `${api}/posts/newImagePost`,
            data,
            headersMultipart()
        );
        return res;
    },
    uploadVideoPost: async (data) => {
        const res = await axios.post(
            `${api}/posts/newVideoPost`,
            data,
            headersJsonAuth()
        );
        return res;
    },
    deleteImagePost: async (data) => {
        const res = await axios.post(
            `${api}/posts/deleteImagePost`,
            data,
            headersJsonAuth()
        );
        return res;
    },
    deleteVideoPost: async (data) => {
        const res = await axios.post(
            `${api}/posts/deleteVideoPost`,
            data,
            headersJsonAuth()
        );
        return res;
    },
    newCategory: async (data) => {
        const res = await axios.post(
            `${api}/categories/newCategory`,
            data,
            headersJsonAuth()
        );
        return res;
    },
    editCategory: async (data) => {
        const res = await axios.post(
            `${api}/categories/editCategory`,
            data,
            headersJsonAuth()
        );
        return res;
    },
    deleteCategory: async (data) => {
        const res = await axios.post(
            `${api}/categories/deleteCategory`,
            data,
            headersJsonAuth()
        );

        return res;
    },
    sendEmail: async (data) => {
        const res = await axios.post(`${api}/email/send`, data);
        return res;
    },
};
