import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import Layout from "./Layout";
import CategoriesPagination from "../../components/categoriesPagination/categoriesPagination";
import CustomPagination from "../../components/customPagination/customPagination";
import VideoBox from "../../components/videoBox/videoBox";
import { ApiService } from "../../services/ApiService";
import Skeletons from "../../components/skeletons/skeletons";

const Videos = () => {
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();

    const [videos, setVideos] = useState([]);

    const urlPage = params.get("page");
    const urlCategory = params.get("category");
    const pageSize = 15; // Cantidad de imágenes por página

    const [page, setPage] = useState(urlPage || 1); // Página actual de imágenes
    const [totalPages, setTotalPages] = useState("");

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(urlCategory);

    const [showNoVideos, setShowNoVideos] = useState(false);
    const [showSkeletons, setShowSkeletons] = useState(false);

    const handleChangePage = async (
        evt,
        pageNumber,
        category = urlCategory
    ) => {
        navigate(`/portfolio/videos?page=${pageNumber}&category=${category}`);
        setShowSkeletons(true);
        await fetchData(pageNumber, category);
        setShowSkeletons(false);
    };

    const fetchData = async (pageNumber, category) => {
        try {
            setPage(pageNumber);
            const res = await ApiService.getPortfolioVideos(
                pageNumber,
                pageSize,
                category
            );
            setVideos(res.data);
            if (res.data.length === 0) {
                setShowNoVideos(true);
            } else {
                setShowNoVideos(false);
            }

            const resTotalPages = await ApiService.getVideosTotalPages(
                page,
                pageSize,
                category
            );

            setTotalPages(resTotalPages.data.totalPages);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (urlCategory === null) {
            setSelectedCategory("all");
        } else {
            setSelectedCategory(urlCategory);
        }

        if (urlPage !== null) {
            fetchData(parseInt(urlPage), urlCategory);
        } else {
            fetchData(1, urlCategory);
        }

        // const getTotalPages = async () => {
        //     const resTotalPages = await ApiService.getVideosTotalPages(
        //         page,
        //         pageSize,
        //         selectedCategory
        //     );

        //     setTotalPages(resTotalPages.data.totalPages);
        // };

        // getTotalPages();
    }, [urlPage, urlCategory]);

    useEffect(() => {
        const getCategories = async () => {
            const res = await ApiService.getVideosCategories();
            setCategories(res.data);
        };

        getCategories();
    }, []);

    const handleChangeCategory = (evt) => {
        console.log(evt.target.value);
        setPage(1);
        setSelectedCategory(evt.target.value);
        handleChangePage(null, 1, evt.target.value);
    };

    return (
        <Layout>
            <CategoriesPagination
                categories={categories}
                selectedCategory={selectedCategory}
                handleChangeCategory={handleChangeCategory}
            />
            {showNoVideos && (
                <Grid item xs={12} sx={{ marginTop: "5%" }}>
                    <h1
                        style={{
                            color: "white",
                            textTransform: "uppercase",
                            textAlign: "center",
                        }}
                    >
                        No videos to show for this category
                    </h1>
                </Grid>
            )}
            {showSkeletons && <Skeletons height={315}></Skeletons>}
            {videos.length !== 0 && (
                <Grid
                    container
                    padding={2}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {videos.map((video) => (
                        <VideoBox key={video.id} url={video.url} />
                    ))}

                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "2%",
                        }}
                    >
                        <CustomPagination
                            totalPages={totalPages}
                            actualPage={page}
                            handleChange={handleChangePage}
                        ></CustomPagination>
                    </Grid>
                </Grid>
            )}
        </Layout>
    );
};

export default Videos;
