import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

export default function HomeNavBar() {
    const { auth } = useAuth();

    const menuItems = [
        {
            id: 1,
            title: "About",
            url: "#about",
        },
        {
            id: 2,
            title: "Portfolio",
            url: "#portfolio",
        },
        {
            id: 3,
            title: "Contact",
            url: "#contact",
        },
    ];

    if (auth !== false) {
        menuItems.push({ id: 4, title: "Admin", url: "/admin" });
    }

    const [currentSection, setCurrentSection] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section[id]");
            const scrollY = window.scrollY;
            const offset = 170; // Ajusta este valor según sea necesario

            if (scrollY === 0) {
                setCurrentSection("");
            }
            sections.forEach((section) => {
                const sectionTop = section.offsetTop - offset;
                const sectionHeight = section.clientHeight;

                if (
                    scrollY >= sectionTop &&
                    scrollY < sectionTop + sectionHeight
                ) {
                    setCurrentSection(section.id);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <nav
            className="navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink"
            id="mainNav"
        >
            <div className="container">
                <a className="navbar-brand" href="#page-top">
                    <img
                        // width={140}
                        // height={24}
                        src="/img/logo.png"
                        alt="..."
                    />
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        {menuItems.map((menuItem) => (
                            <li className="nav-item" key={menuItem.id}>
                                <a
                                    className={`nav-link ${
                                        currentSection ===
                                        menuItem.title.toLowerCase()
                                            ? "active"
                                            : ""
                                    }`}
                                    href={menuItem.url}
                                >
                                    {menuItem.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
