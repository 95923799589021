import React from "react";

export default function Footer() {
    return (
        <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-lg-4 text-lg-start">
                        Copyright &copy; Clara Asprella 2023
                    </div> */}
                    <div className="col-lg-12 my-3 my-lg-0">
                        <a
                            className="btn btn-dark btn-social mx-2"
                            aria-label="Clara Asprella Instagram Profile"
                            href="https://www.instagram.com/asprellafilm/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a
                            className="btn btn-dark btn-social mx-2"
                            aria-label="Clara Asprella YouTube Profile"
                            href="https://www.youtube.com/@asprellafilm"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fab fa-youtube"></i>
                        </a>
                        <a
                            className="btn btn-dark btn-social mx-2"
                            aria-label="Clara Asprella Indeed Profile"
                            href="https://indeed.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                style={{
                                    width: "60%%",
                                    height: "20px",
                                    borderRadius: "50%",
                                    marginRight: "2px",
                                }}
                                alt="Indeed Icon"
                                src="/indeed.png"
                            />
                        </a>
                    </div>
                    {/* <div className="col-lg-4 text-lg-end">
                        <a
                            className="link-dark text-decoration-none me-3"
                            href="#!"
                        >
                            Privacy Policy
                        </a>
                        <a className="link-dark text-decoration-none" href="#!">
                            Terms of Use
                        </a>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}
