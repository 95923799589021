import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

const DialogComponent = ({
    open,
    handleCloseNo,
    handleCloseYes,
    title,
    text,
}) => {
    return (
        <Dialog
            // fullScreen
            open={open}
            onClose={handleCloseNo}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCloseNo}>
                    No
                </Button>
                <Button onClick={handleCloseYes} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogComponent;
