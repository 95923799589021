import React from "react";
import { Grid } from "@mui/material";

const VideoBox = ({ url }) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
                display: "flex",
                justifyContent: "center",
                padding: 1,
            }}
        >
            <iframe
                width="600"
                height="315"
                src={url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            ></iframe>
        </Grid>
    );
};

export default VideoBox;
