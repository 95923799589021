import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import "./styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PortfolioModal({ image, open, setOpen }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open full-screen dialog
            </Button> */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                TransitionComponent={Transition}
                className="image-dialog"
            >
                <img
                    src={`${process.env.REACT_APP_AWS_IMAGES_ENDPOINT}/${image}`}
                    className="image-modal"
                    alt="modal"
                    width={"80%"}
                ></img>
            </Dialog>
        </React.Fragment>
    );
}
