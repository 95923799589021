import React from "react";

const Header = () => {
    return (
        <header className="masthead">
            <div className="video-container">
                <video src="/img/header-bg.mp4" autoPlay muted loop>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="container">
                <div className="masthead-heading text-uppercase">
                    CLARA ASPRELLA
                </div>
                <div className="masthead-subheading">Argentina</div>
                <a
                    className="btn btn-bordo btn-xl text-uppercase"
                    href="#portfolio"
                >
                    Portfolio
                </a>
            </div>
        </header>
    );
};

export default Header;
