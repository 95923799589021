import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import FormInputs from "./FormInputs";
import { ApiService } from "../../services/ApiService";

const PostForm = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [severity, setSeverity] = useState("");

    const [selectedPostType, setSelectedPostType] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [imagePreviews, setImagePreviews] = useState([]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");

    const handleChangePostType = async (evt) => {
        resetForm();
        const typeId = evt.target.value;
        setSelectedPostType(typeId);
        if (typeId === 1) {
            const imgCategs = await ApiService.getImagesCategories();
            setCategories(imgCategs.data);
        } else {
            const imgCategs = await ApiService.getVideosCategories();
            setCategories(imgCategs.data);
        }
    };

    const handleChangeCategory = (evt) => {
        setSelectedCategory(evt.target.value);
    };

    const handleChangeVideoUrl = (evt) => {
        setVideoUrl(evt.target.value);
    };

    const checkFormData = (evt) => {
        evt.preventDefault();
        saveData(evt.currentTarget);
    };

    const resetForm = () => {
        setSelectedPostType("");
        setSelectedCategory("");
        setCategories([]);
        setVideoUrl("");
        setImagePreviews("");
    };

    const saveData = async (data) => {
        setShowLoader(true);
        const form = new FormData(data);

        try {
            let res = "";
            if (selectedPostType === 1) {
                res = await ApiService.uploadImagePost(form);
            } else {
                res = await ApiService.uploadVideoPost({
                    category: selectedCategory,
                    videoUrl: videoUrl,
                });
            }
            setShowLoader(false);
            if (res.status === 200) {
                console.log("entro");
                setShowAlert(true);
                setAlertMessage(res.message);
                setSeverity("success");
                resetForm();
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMessage("");
                }, 2000);
            } else {
                setShowAlert(true);
                setAlertMessage("Ocurrió un error, intente nuevamente!");
                setSeverity("error");
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMessage("");
                }, 2000);
            }
        } catch (error) {
            setShowAlert(true);
            setAlertMessage("Ocurrió un error, intente nuevamente!");
            setTimeout(() => {
                setShowAlert(false);
                setAlertMessage("");
            }, 2000);
        }
    };

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} md={6}>
                <FormInputs
                    checkFormData={checkFormData}
                    showLoader={showLoader}
                    selectedPostType={selectedPostType}
                    handleChangePostType={handleChangePostType}
                    categories={categories}
                    selectedCategory={selectedCategory}
                    handleChangeCategory={handleChangeCategory}
                    videoUrl={videoUrl}
                    handleChangeVideoUrl={handleChangeVideoUrl}
                    imagePreviews={imagePreviews}
                    setImagePreviews={setImagePreviews}
                ></FormInputs>
            </Grid>
            {showLoader && (
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={showLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
        </Grid>
    );
};

export default PostForm;
