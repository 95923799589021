import React from "react";
import { Grid, Skeleton } from "@mui/material";

const Skeletons = ({ height }) => {
    return (
        <Grid container padding={3}>
            <Grid item xs={12} md={4}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginTop: "10px" }}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginTop: "10px" }}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4} sx={{ marginTop: "10px" }}>
                <Skeleton
                    variant="rectangular"
                    width={"98%"}
                    height={height}
                    sx={{
                        borderRadius: "5px",
                        backgroundColor: "rgb(169 164 164 / 11%)",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default Skeletons;
