import { React } from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";

export default function PortfolioArea() {
    const navigate = useNavigate();

    const items = [
        {
            id: 1,
            image: "/img/photography.jpeg",
            title: "Photography",
            // subtitle: "Illustration",
            url: "/portfolio/photography?page=1&category=all",
        },
        {
            id: 2,
            image: "/img/videos.jpeg",
            title: "Videos",
            // subtitle: "Graphic Design",
            url: "/portfolio/videos?page=1&category=all",
        },
    ];

    return (
        <section className="page-section bg-light" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2
                        className="section-heading text-uppercase"
                        style={{ marginBottom: "4rem" }}
                    >
                        Portfolio
                    </h2>
                    {/* <h3 className="section-subheading text-muted">
                        Lorem ipsum dolor sit amet consectetur.
                    </h3> */}
                </div>
                {/* <div className="row"> */}
                <Grid
                    container
                    gap={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    {items.map((item) => (
                        <Grid item xs={12} md={5} key={item.url}>
                            {/* <div
                                    className="col-lg-6 col-sm-6 mb-4"
                                    key={item.id}
                                > */}
                            {/* <!-- Portfolio item 1--> */}
                            <div className="portfolio-item">
                                <button
                                    className="portfolio-link"
                                    onClick={() => navigate(item.url)}
                                >
                                    <div className="portfolio-hover"></div>
                                    <img
                                        width={"100%"}
                                        // width={416}
                                        height={312}
                                        className="Image-fluid"
                                        src={item.image}
                                        alt="..."
                                    />
                                </button>
                                <div className="portfolio-caption">
                                    <div className="portfolio-caption-heading">
                                        {item.title}
                                    </div>
                                    {/* <div className="portfolio-caption-subheading text-muted">
                                        {item.subtitle}
                                    </div> */}
                                </div>
                            </div>
                            {/* </div> */}
                        </Grid>
                    ))}
                </Grid>
                {/* </div> */}
            </div>
        </section>
    );
}
